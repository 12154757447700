import React from "react";

export default function ShowMoreIcon({ ...props }: React.SVGProps<any>) {
  return (
    <svg width="24" height="12" viewBox="0 0 24 12" fill="none" {...props}>
      <path
        d="M2 2.5L11.7436 9.55882L22 2.5"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
