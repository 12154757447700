import React from "react";
import { Button } from "@govlaunch/web";
import ShowMoreIcon from "~/components/icons/Collections/ShowMoreArrow";
import { FormattedMessage } from "react-intl";

interface IShowMoreButtonProps {
  loading: boolean;
  onClick: () => void;
}

export default function ShowMoreButtonWithIcon({ loading, onClick }: IShowMoreButtonProps) {
  return (
    <Button variant="secondary" loading={loading} size="sm" rightIcon={ShowMoreIcon} onClick={onClick}>
      <FormattedMessage id="aWpBzj" defaultMessage="Show more" />
    </Button>
  );
}
